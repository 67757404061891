import Highcharts from "highcharts";
import { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { FiSearch } from "react-icons/fi";
import { HiCheck, HiPlus } from "react-icons/hi";
import { MdRefresh } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import ToggleBtn from "../../components/pc/admin/ToggleBtn";
import PcEssayChart from "../../components/pc/gpt/PcEssayChart";
import PcGptFetchModal from "../../components/pc/gpt/PcGptFetchModal";
import useGetAIChartRes from "../../hooks/useGetAIChartRes";
import useGetEssayChartRes from "../../hooks/useGetEssayChartRes";

import useGetEssayListRes from "../../hooks/useGetEssayListRes";
import useGetEssayQstInfo from "../../hooks/useGetEssayQstInfo";
import useGetGptRes from "../../hooks/useGetGptRes";
import { useEssayListState, useMergedStrState, usePcEssayOptState, usePeriodState, useSummerizeRes } from "../../store/mobile/store";
import useStore from "../../store/pc/store";
const colors = ["#DF895F", "#ECB758", "#98C8D7", "#72BAB4", "#63ABCB", "#D977A0", "#E19DBA", "#EBBA94"];
const ShortPage = () => {
  const Pick = useStore((state) => state.Pick);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const userId = useStore((state) => state.LoginId);

  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);

  const [finalSearchKeyword, setFinalSearchKeyword] = useState("A");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [render, setRender] = useState(false);

  const opt = usePcEssayOptState((state) => state.opt);
  const setOpt = usePcEssayOptState((state) => state.setOpt);

  const ADMIN = ["JJLIM", "GMNA", "SHPARK", "BSKIM"];

  // const [loading, setLoading] = useState(false);
  const LoginId = useStore((state) => state.LoginId);

  // aiChart mode State
  const [toggle, setToggle] = useState(false);
  const [aiRender, setAiRender] = useState(false); // 첫번째 랜더링이 되었다면 true, 아니면 false
  const [aiSwitchRender, setAiSwitchRender] = useState(true);

  const summerizedData = useSummerizeRes((state) => state.summerizedData);
  const [activeModal, setActiveModal] = useState(false);

  const [scrollFetch, setScrollFetch] = useState(false);
  const [page, setPage] = useState(0);

  const mergedStr = useMergedStrState((state) => state.mergedStr);

  const setMergedStr = useMergedStrState((state) => state.setMergedStr);
  const [wordCountData, setWordCountData] = useState([]);

  const { refetch, data } = useGetEssayQstInfo(PrjCd, Pick.qst_cd, SvyOjt);
  const {
    refetch: listRefetch,
    isRefetching,
    isSuccess,
  } = useGetEssayListRes(PrjCd, Pick.qst_cd, SvyOjt, opt, finalSearchKeyword === "" ? "A" : finalSearchKeyword, 15, page * 15, startDate, endDate);

  const {
    refetch: chartRefetch,
    data: chartData,
    isRefetching: chartRefetching,
  } = useGetEssayChartRes(PrjCd, Pick.qst_cd, SvyOjt, opt, startDate, endDate);

  const { refetch: aiChartRefetch } = useGetAIChartRes(PrjCd, SvyOjt, Pick.qst_cd, opt, `${startDate}-${endDate?.substring(0, 10)}`);

  const { refetch: gptResRefetch } = useGetGptRes(
    PrjCd,
    Pick.qst_cd,
    SvyOjt,
    opt.length === data?.data.comn_CD.length || opt.length === 0 ? ["-"] : opt,
    startDate ? `${startDate}-${endDate?.substring(0, 10)}` : "-"
  );

  const listData = useEssayListState((state) => state.listData);

  const answerRef = useRef(null);

  const navigate = useNavigate();

  // let initData = chartData?.data;

  const handleClickRefresh = () => {
    setActiveModal(true);
  };

  const handleClickBg = (e) => {
    // setActiveModal(false);
  };

  const extractTime = (time) => {
    let currDate = new Date(time);

    let utc = currDate.getTime() + currDate.getTimezoneOffset() * 60 * 1000;

    // const KR_TIME_DIFF = 18 * 60 * 60 * 1000;
    const KR_TIME_DIFF = 9 * 60 * 60 * 1000;

    const kr_curr = new Date(utc + KR_TIME_DIFF);

    let year = kr_curr.getFullYear();
    let month = Number(kr_curr.getMonth()) + 1 > 9 ? Number(kr_curr.getMonth()) + 1 : `0${Number(kr_curr.getMonth()) + 1}`;
    let date = kr_curr.getDate() > 9 ? kr_curr.getDate() : `0${kr_curr.getDate()}`;

    let hour = kr_curr.getHours() > 9 ? kr_curr.getHours() : `0${kr_curr.getHours()}`;
    let minute = kr_curr.getMinutes() > 9 ? kr_curr.getMinutes() : `0${kr_curr.getMinutes()}`;
    let sec = kr_curr.getSeconds() > 9 ? kr_curr.getSeconds() : `0${kr_curr.getSeconds()}`;

    return `${year}.${month}.${date} ${hour}:${minute}:${sec}`;
  };

  // console.log(chartData);
  const extractWordCount = (text) => {
    const lines = text ? text?.replace(/[():'?0-9]+/g, "").split(/[,\. \n]+/g) : [""];

    const data = lines
      .reduce((arr, word, idx) => {
        let obj = Highcharts.find(arr, (obj) => obj.name === word);

        if (obj) {
          obj.weight += 1;
        } else {
          obj = {
            name: word,
            weight: 1,
            color: colors[idx % 8],
          };
          arr.push(obj);
        }
        return arr;
      }, [])
      .sort((a, b) => b.weight - a.weight)
      .slice(0, 100);

    return data;
  };

  const handleChangeSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
  };

  const handleClickActiveBtn = (comn_cd) => {
    if (opt.length === 1) {
      alert("최소 1개 이상의 옵션이 필요합니다");
    } else {
      let tempArr = [...opt];

      let idx = tempArr.indexOf(comn_cd);

      tempArr.splice(idx, 1);

      setOpt(tempArr.sort((a, b) => a - b));
      setPage(0);
      answerRef.current.scrollTop = 0;
    }
  };

  const handleClickInActiveBtn = (comn_cd) => {
    let tempArr = opt.length === data?.data.comn_CD.length ? [] : [...opt];

    tempArr.push(comn_cd);

    setOpt(tempArr.sort((a, b) => a - b));

    setPage(0);

    answerRef.current.scrollTop = 0;
  };

  const handleClickAllPlusBtn = () => {
    let tempArr = [];

    data?.data.comn_CD.forEach((el) => tempArr.push(el.value));

    setOpt(tempArr.sort((a, b) => a - b));
    answerRef.current.scrollTop = 0;
  };

  const handleClickScroll = (e) => {
    if (e.target.scrollTop + 1000 > e.target.scrollHeight) {
      if (page < listData[0]?.tot_PAGE && !isRefetching) {
        setScrollFetch(true);
        setTimeout(() => {
          setScrollFetch(false);
          setPage(page + 1);
        }, 1000);
      }
    }
  };

  const handleClickSearch = () => {
    setFinalSearchKeyword(searchKeyword);
    setPage(0);
    answerRef.current.scrollTop = 0;
  };

  const handlePressEnter = (e) => {
    if (e.keyCode === 13) {
      setFinalSearchKeyword(searchKeyword);
      setPage(0);
      answerRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    refetch();
  }, [Pick]);

  useEffect(() => {
    if (data) {
      let tempArr = [];

      data?.data.comn_CD.forEach((el) => tempArr.push(el.value));

      setOpt(tempArr.sort((a, b) => a - b));
    }
  }, [data]);

  useEffect(() => {
    // console.log(opt);
    setPage(0);

    if (opt.length > 0) {
      listRefetch();
      chartRefetch();

      gptResRefetch();
      if (data?.data.qst_INFO[0]?.qst_TYP === "Q02" || data?.data.qst_INFO[0]?.qst_TYP === "Q14") {
      }
    }
  }, [opt, startDate, endDate]);

  useEffect(() => {
    if (opt.length > 0) {
      listRefetch();
    }
  }, [page]);

  useEffect(() => {
    if (finalSearchKeyword !== "A") {
      listRefetch();
    }
  }, [finalSearchKeyword]);

  useEffect(() => {
    let str = "";
    let chartAiStr = "";

    chartData?.data.forEach((el, idx) => {
      str = str + "\n" + el.sbjt_ANSW;
    });

    chartData?.data.forEach((el, idx) => {
      if (el.sbjt_AI_ANSW !== null && el.sbjt_AI_ANSW !== "" && el.sbjt_AI_ANSW !== " ") {
        chartAiStr = chartAiStr + "\n" + el.sbjt_AI_ANSW;
      }
    });

    if (chartAiStr.length === 0) {
      setToggle(false);
      setAiSwitchRender(false);
    } else {
      setToggle(true);
      setAiSwitchRender(true);
    }
    setAiRender(true);

    setMergedStr(str);
    setWordCountData(extractWordCount(toggle && chartAiStr.length > 0 ? chartAiStr : str));

    return () => {
      setAiRender(false);
    };
  }, [chartData?.data]);

  useEffect(() => {
    if (aiRender) {
      let str = "";
      let chartAiStr = "";

      chartData?.data.forEach((el, idx) => {
        str = str + "\n" + el.sbjt_ANSW;
      });

      chartData?.data.forEach((el, idx) => {
        if (el.sbjt_AI_ANSW !== null && el.sbjt_AI_ANSW !== "" && el.sbjt_AI_ANSW !== " ") {
          chartAiStr = chartAiStr + "\n" + el.sbjt_AI_ANSW;
        }
      });

      if (chartAiStr.length === 0) {
        setToggle(false);
      }

      setMergedStr(str);
      setWordCountData(extractWordCount(toggle && chartAiStr.length > 0 ? chartAiStr : str));
    }
  }, [toggle]);

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }

    setTimeout(() => {
      setRender(true);
    }, 1000);

    return () => {
      setAiSwitchRender(true);
    };
  }, []);

  return (
    <>
      {render && opt.length > 0 ? (
        <div className="relative h-full w-full flex-col items-center justify-start p-10 pr-0 font-SDGothic text-[16px] font-bold text-selectTextColor/80">
          {activeModal ? (
            <div onClick={handleClickBg} className="absolute top-0 z-50 mt-[500px] flex h-full w-full flex-col items-center justify-start">
              <PcGptFetchModal
                setModal={setActiveModal}
                data={mergedStr?.substring(0, 450000)}
                filterOpt={opt}
                optLength={data?.data.comn_CD.length}
              />
              {/* <GptFetchModal data={props?.data} setModal={setActiveModal} filterOpt={props?.filterOpt} /> */}
            </div>
          ) : null}
          {/* qst */}
          <div className="flex h-fit w-full flex-row items-center justify-start">
            <img
              src="https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/image/essay_qst_icon.png"
              alt="menu_dot"
              className="mb-1 mr-5 w-[30px]"
            />
            <div className="flex h-fit w-fit flex-col items-center justify-center text-[20px] leading-snug">{data?.data.qst_INFO[0]?.qst}</div>
          </div>

          {/* subTitle */}
          <div className="flex h-fit w-full flex-col items-start justify-start py-3">
            <div className="mt-10 h-fit w-fit text-[18px] font-bold">검색조건</div>
            <div className="mt-6 h-fit w-fit text-[14px] font-bold  text-selectTextColor/50">원하는 조건을 선택하여 답변결과를 확인하세요</div>
          </div>

          {/* Filter comp */}
          <div className="mt-5 flex h-[60px] w-full flex-row items-center justify-start border-b border-t-2 border-b-FilterInActiveColor border-t-orangeColor bg-tableBodyGray p-5">
            {/* 전체 */}
            {opt.length === data?.data.comn_CD.length ? (
              <div
                onClick={() => alert("최소 1개 이상의 옵션이 필요합니다")}
                className=" flex h-fit w-fit cursor-pointer flex-row items-center justify-center rounded-[25px] border-2 border-FilterActiveColor/80 bg-white px-7 py-3 font-SDGothic text-[14px] font-bold text-selectTextColor/90 hover:bg-FilterInActiveColor/10"
              >
                <HiCheck className="mr-2 text-[18px] text-orangeColor/80" />
                <div className="flex h-fit w-fit flex-col items-center justify-center">전체</div>
              </div>
            ) : (
              <div
                onClick={() => handleClickAllPlusBtn()}
                className="flex h-fit w-fit cursor-pointer flex-row items-center justify-center rounded-[25px] border-2 border-FilterInActiveColor bg-white px-7 py-3 font-SDGothic text-[14px] font-bold text-selectTextColor/60 hover:bg-FilterInActiveColor/10"
              >
                <HiPlus className="mr-2 text-[18px] text-selectTextColor/60" />
                <div className="flex h-fit w-fit flex-col items-center justify-center">전체</div>
              </div>
            )}

            {data?.data.comn_CD.map((el, idx) => {
              if (opt.includes(el.value) && opt.length < data?.data.comn_CD.length) {
                return (
                  <div
                    key={idx}
                    onClick={() => handleClickActiveBtn(el.value)}
                    className="ml-4 flex h-fit w-fit cursor-pointer flex-row items-center justify-center rounded-[25px] border-2 border-FilterActiveColor/80 bg-white px-7 py-3 font-SDGothic text-[14px] font-bold text-selectTextColor/90 hover:bg-FilterInActiveColor/10"
                  >
                    <HiCheck className="mr-2 text-[18px] text-orangeColor/80" />
                    <div className="flex h-fit w-fit flex-col items-center justify-center">{el.label}</div>
                  </div>
                );
              } else {
                return (
                  <div
                    key={idx}
                    onClick={() => handleClickInActiveBtn(el.value)}
                    className="ml-4 flex h-fit w-fit cursor-pointer flex-row items-center justify-center rounded-[25px] border-2 border-FilterInActiveColor bg-white px-7 py-3 font-SDGothic text-[14px] font-bold text-selectTextColor/60 hover:bg-FilterInActiveColor/10"
                  >
                    <HiPlus className="mr-2 text-[18px] text-selectTextColor/60" />
                    <div className="flex h-fit w-fit flex-col items-center justify-center">{el.label}</div>
                  </div>
                );
              }
            })}
          </div>

          {/* word graph */}
          {data?.data.qst_INFO[0]?.qst_TYP === "Q02" || data?.data.qst_INFO[0]?.qst_TYP === "Q14" ? (
            <div className="mt-5 flex h-fit w-full flex-col items-center justify-center text-[16px]">
              {/* <div
                  className={
                    aiAlertRender
                    ? `absolute -bottom-12 right-0 z-50 flex h-fit w-fit flex-col items-center justify-center rounded-xl bg-orangeColor/90 px-4 py-4 text-[14px] text-white opacity-100 duration-200 ease-in-out`
                    : `absolute -bottom-12 right-0 z-50 flex h-fit w-fit flex-col items-center justify-center rounded-xl bg-orangeColor/90 px-4 py-4 text-[14px] text-white opacity-0 duration-200 ease-in-out`
                  }
                  >
                  AI 추출데이터가 존재하지 않습니다
                </div> */}
              {aiSwitchRender ? (
                <div className="flex h-fit w-full flex-row items-center justify-between">
                  <div className="flex h-fit w-fit flex-col items-center justify-center">주요 키워드</div>
                  <div className="flex h-fit w-fit flex-row items-center justify-center">
                    <div className="mr-2 flex h-full w-fit flex-col items-center justify-center">AI모드</div>
                    {/* <div className=" mr-2 flex h-fit w-fit flex-col items-center justify-center rounded-md bg-blue1Color p-3 text-[14px] text-white ">
                    IBM AI
                  </div> */}
                    {/* toggle switch */}
                    <div className="flex h-fit w-fit flex-row items-center justify-center text-[16px] font-bold">
                      <ToggleBtn toggle={toggle} setToggle={setToggle} />
                    </div>
                  </div>
                </div>
              ) : null}
              {/* wordGraph */}
              <div className="flex h-[350px] w-full flex-col items-center justify-center">
                {!toggle && wordCountData.length === 0 ? (
                  <SyncLoader color="#1686FF" />
                ) : (
                  <>
                    {/* wordGraph */}
                    {wordCountData && !chartRefetching ? <PcEssayChart data={wordCountData} /> : null}
                  </>
                )}
              </div>

              {/* gptArea */}
              <div className="relative flex h-fit w-full flex-col items-start justify-start rounded-lg border  border-FilterInActiveColor bg-white p-6 font-SDGothic  duration-300 ease-in-out">
                <div className="flex h-fit w-full flex-row items-center justify-start text-[16px] font-bold text-selectTextColor/90">
                  <img
                    src="https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/image/chatGptIcon.png"
                    alt="chatGptIcon"
                    className="mr-2  h-auto w-[20px]"
                  />
                  <div className="ml-2 mt-1 flex h-fit w-fit flex-row items-center justify-center">
                    {opt.length === data?.data.comn_CD.length
                      ? "ChatGPT로 전체 답변을 요약한 글입니다"
                      : "ChatGPT로 선택한 조건의 답변을 요약한 글입니다"}
                  </div>
                  {summerizedData?.data?.gpt_RSLT ? (
                    <div className="ml-4 flex h-fit w-fit flex-col items-center justify-center rounded-lg bg-FilterInActiveColor/50 p-3 font-SDGothic text-[13px] font-bold text-selectTextColor/50">
                      {`최종 업데이트 : ${extractTime(summerizedData?.data?.inpt_DTM)}`}
                    </div>
                  ) : null}
                </div>

                {/* content */}
                <p
                  className={
                    "mt-5 flex max-h-fit w-full flex-row items-start justify-start overflow-hidden  text-ellipsis text-[16px] font-light leading-snug text-selectTextColor/90 transition-all duration-300 ease-in-out"
                  }
                >
                  {summerizedData?.data?.gpt_RSLT
                    ? summerizedData?.data?.gpt_RSLT
                    : "요약정보가 존재하지 않습니다. 우측 상단의 버튼을 눌러 GPT 요약정보를 요청하세요"}
                </p>
                {ADMIN.includes(userId.toUpperCase()) ? (
                  <div
                    onClick={handleClickRefresh}
                    className="absolute right-3 top-3 flex h-fit w-fit cursor-pointer flex-col items-center justify-center rounded-md border border-FilterActiveColor/50 p-1"
                  >
                    <MdRefresh className=" text-[16px] text-FilterActiveColor/50" />
                  </div>
                ) : summerizedData?.data?.prog_YN === "Y" ? (
                  <div
                    onClick={handleClickRefresh}
                    className="absolute right-3 top-3 flex h-fit w-fit flex-col items-center justify-center rounded-md border border-FilterActiveColor/50 p-1"
                  >
                    <MdRefresh className=" text-[16px] text-FilterActiveColor/50" />
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}

          {/* answer comp */}
          <div className="mt-10 flex h-fit w-full flex-row items-center justify-between">
            {/* answer title */}
            <div className="mt-3 flex h-fit w-full flex-row items-start justify-start">
              <img
                src="https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/image/orange_menu_rectangle.png"
                alt="menu_dot"
                className="mb-1 mr-5 w-[8px]"
              />
              <div className="h-fit w-fit text-[18px] font-bold">답변 ({listData.length !== 0 ? listData[0]?.cnt : 0})</div>
            </div>

            {/* search Area */}
            <div className="flex h-fit w-[60%] flex-row items-center justify-between rounded-lg border border-FilterInActiveColor p-4">
              <input
                type="text"
                value={searchKeyword}
                onKeyUp={handlePressEnter}
                onChange={handleChangeSearchKeyword}
                placeholder="검색할 키워드를 입력하세요"
                className="h-fit w-[90%]  font-SDGothic text-[16px] font-normal text-selectTextColor outline-none"
              />
              <FiSearch onClick={handleClickSearch} className="mb-1 cursor-pointer text-[20px] text-orangeColor" />
            </div>
          </div>

          {/* answer area */}
          <div
            ref={answerRef}
            className="mt-8 flex h-[800px] w-full flex-col items-start justify-start rounded-lg border  border-FilterInActiveColor bg-tableBodyGray p-10 pr-0"
          >
            <Scrollbars
              onScroll={handleClickScroll}
              renderTrackVertical={({ style, ...props }) => (
                <div
                  {...props}
                  style={{
                    ...style,
                    backgroundColor: "transparent",
                    right: "2px",
                    bottom: "2px",
                    top: "2px",
                    borderRadius: "3px",
                    width: "10px",
                  }}
                />
              )}
              renderThumbVertical={({ style, ...props }) => (
                <div
                  {...props}
                  style={{
                    ...style,
                    width: "7px",
                    borderRadius: "4px",
                    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                    backgroundColor: "#6C6C6C",
                    opacity: "60%",
                  }}
                />
              )}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={300}
            >
              {listData?.map((el, idx) => {
                return (
                  <div className="mb-10 mr-10 flex h-fit w-fit flex-row items-end justify-start">
                    <div className="flex h-fit w-fit flex-col items-center justify-start rounded-2xl rounded-tl-none border border-FilterInActiveColor  bg-white p-8 font-normal leading-snug">
                      {`${el.seq}. ${el.sbjt_ANSW}`}
                    </div>
                    {window.location.href.toLowerCase().indexOf("csscan") > -1 ? (
                      <div className="flex h-full w-fit flex-col items-end justify-end whitespace-nowrap pb-3 pl-3 text-[14px] font-bold text-selectTextColor/50">
                        {extractTime(el.inpt_DTM)}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </Scrollbars>
            {scrollFetch ? (
              <div className="mb-32 mt-10 flex h-fit w-full flex-col items-center justify-center p-3">
                <SyncLoader color="#1686FF" />
              </div>
            ) : null}
          </div>
          {/* {Pick.qst_cd} */}
        </div>
      ) : (
        <div className="relative h-full w-full flex-col items-center justify-start p-10 pr-0 font-SDGothic text-[16px] font-bold text-selectTextColor/80">
          로딩중
        </div>
      )}
    </>
  );
};

export default ShortPage;
