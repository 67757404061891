import exceljs from "exceljs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useTrendMedicalProc } from "../../hooks/useTrendMedicalProc";
import { useTrendNpsBasic } from "../../hooks/useTrendNpsBasic";
import { useTrendSatisfaction } from "../../hooks/useTrendSatisfaction";
import { useTrendUsingExp } from "../../hooks/useTrendUsingExp";
import { useTrendVisitWay } from "../../hooks/useTrendVisitWay";
import {
  exportGraphDataMatchPeriodNPS,
  exportSelList,
  GraphDataMatchPeriodUsingExp,
  GraphDataMatchPeriodVisitWay,
} from "../../utils/TrendSummaryFunc";
const html2canvas = require("html2canvas");

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const TrendSummary = ({
  hsp_nm,
  prj_cd,
  svy_ojt,
  qst_cd,
  deep_ana_cd,
  sel_cd,
  string_value,
  where,
  sort_type,
  data_num,
  title,
  qst,
  menu_cate,
  qst_hsp_sel_cd,
  grp_dvs,
  startDate,
  endDate,
  // trends_period_x,
  // trends_period_tmp,
  trendPeriod,
}) => {
  const [FirstCheck, setFirstCheck] = useState([]);
  const [SecondCheck, setSecondCheck] = useState([]);
  const [DA08Check, setDA08Check] = useState("");
  const [DA08CheckNm, setDA08CheckNm] = useState("");
  const [OptionCnt, setOptionCnt] = useState(0);
  const [graphWideFull, setGraphWideFull] = useState(false);
  const [selList, setSelList] = useState([]);
  Highcharts.setOptions({
    colors: ["#DF895F", "#ECB758", "#98C8D7", "#72BAB4", "#63ABCB", "#D977A0", "#E19DBA", "#EBBA94", "#FFDC83", "#FF82FF"],
  });

  const chart = useRef();

  const convertToCSV = () => {
    const currChart = chart.current.chart;
    const csvData = currChart.getCSV();

    return csvData;
  };

  const downloadChart = (type) => {
    if (chart && chart.current && chart.current.chart) {
      if (type === "jpeg") {
        chart.current.chart.exportChart({ type: "image/jpeg" });
      } else if (type === "png") {
        chart.current.chart.exportChart({ type: "image/png" });
      } else if (type === "pdf") {
        chart.current.chart.exportChart({
          type: "application/pdf",
        });
      } else {
        // 엑셀 워크북 생성
        let workbook = new exceljs.Workbook();
        // 시트 생성
        const imageSheet = workbook.addWorksheet("DataSheet");

        // 텍스트 데이터 csv로 변환
        const csvData = convertToCSV();
        // 불필요한 특수문자 제거 (")
        let filteredCsvData = csvData.replace(/\"/gi, "");

        // excelJs에 맞는 형식으로 전환
        let excelData = [];

        // 개행태그를 기준으로 split
        let dataArr = filteredCsvData.split("\n");

        // ,단위로 split하여 배열로 만든 후, 덩어리를 excelData에 push
        for (let i = 0; i < dataArr.length; i++) {
          let temp = dataArr[i].split(",");

          excelData.push(temp);
        }

        // 시트에 추가
        imageSheet.addRows(excelData);

        // 차트 이미지 작업을 위한 ref 속성 가져오기
        const chartContainer = chart.current.container.current;

        let promise = [];

        // 차트이미지를 스크린샷 찍어 이미지를 시트에 추가
        promise.push(
          html2canvas(chartContainer).then((c) => {
            let image = c.toDataURL();
            const imageId2 = workbook.addImage({
              base64: image,
              extension: "png",
            });

            imageSheet.addImage(imageId2, {
              tl: { col: 0, row: excelData.length + 3 },
              br: { col: chartContainer.offsetWidth / 100, row: excelData.length + 12 + chartContainer.offsetHeight / 100 },
            });
          })
        );

        // 파일 다운로드
        Promise.all(promise).then(() => {
          workbook.xlsx.writeBuffer().then((b) => {
            let a = new Blob([b]);
            let url = window.URL.createObjectURL(a);

            let elem = document.createElement("a");
            elem.href = url;
            elem.download = `${qst}.xlsx`;
            document.body.appendChild(elem);
            elem.style = "display: none";
            elem.click();
            elem.remove();
          });
        });

        return workbook;
      }
    }
  };

  const initialOptions = {
    title: { text: "" },
    chart: {
      type: "column",
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      animation: false,
      height: 400,
      spacing: [10, 10, 10, 10],
    },
    credits: {
      enabled: false,
    },
    exporting: {
      scale: 2,
      enabled: false,
      filename: qst + "_" + title,
      chartOptions: {
        chart: {
          spacing: [30, 10, 30, 50],
        },
        xAxis: {
          labels: {
            style: {
              fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
              fontSize: "12px",
            },
          },
        },
        yAxis: {
          title: {
            text: "", // y축 레이블을 빈 문자열로 설정하여 제거
          },
          labels: {
            style: {
              fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
              fontSize: "12px",
            },
          },
        },
        legend: {
          itemStyle: {
            fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
            fontSize: "12px",
          },
        },
        plotOptions: {
          series: {
            dataLabels: {
              style: {
                fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                fontSize: "12px",
              },
            },
          },
        },
      },
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "top",
        y: 0,
      },
    },
    boost: {
      enabled: true,
      useGPUTranslations: true,
      seriesThreshold: 1,
    },
    plotOptions: {
      series: {
        dataLabels: {
          style: { fontSize: "1.4rem", textShadow: false, textOutLine: false },
          format: "{y}",
          border: null,
        },
      },
      column: {
        dataLabels: {
          enabled: true,
          inside: true,
          style: {
            textShadow: 0,
            textOutline: 0,
            color: "#000000",
          },
        },
        stacking: "",
        pointWidth: 40,
      },
      bar: {
        dataLabels: {
          enabled: true,
          inside: true,
          style: {
            textShadow: 0,
            textOutline: 0,
            color: "#000000",
          },
        },
        stacking: "",
        pointWidth: 30,
      },
    },
    tooltip: {
      style: {
        fontSize: "1.4rem",
      },
    },
    xAxis: {},
    yAxis: {
      title: false,
      gridLineWidth: 0,
      labels: {
        enabled: false, // y축 값 표시를 비활성화
      },
    },
    legend: {
      enabled: false,
      itemStyle: {
        fontSize: "1.4rem",
      },
    },
    series: [], // 데이터가 처음엔 비어았다.
  };

  const [options, setOptions] = useState({});

  const { isTrendNpsBasicLoading, isTrendNpsBasicError, TrendNpsBasicData, isTrendNpsBasicSuccess, TrendNpsBasicRefetch, isTrendNpsBasicFetching } =
    useTrendNpsBasic(prj_cd, qst_cd, startDate, endDate, trendPeriod);

  const { isTrendUsingExpLoading, isTrendUsingExpError, TrendUsingExpData, isTrendUsingExpSuccess, TrendUsingExpRefetch, isTrendUsingExpFetching } =
    useTrendUsingExp(prj_cd, qst_cd, startDate, endDate, trendPeriod);

  const {
    isTrendSatisfactionLoading,
    isTrendSatisfactionError,
    TrendSatisfactionData,
    isTrendSatisfactionSuccess,
    TrendSatisfactionRefetch,
    isTrendSatisfactionFetching,
  } = useTrendSatisfaction(prj_cd, qst_cd, startDate, endDate, trendPeriod);

  const {
    isTrendMedicalProcLoading,
    isTrendMedicalProcError,
    TrendMedicalProcData,
    isTrendMedicalProcSuccess,
    TrendMedicalProcRefetch,
    isTrendMedicalProcFetching,
  } = useTrendMedicalProc(prj_cd, qst_cd, startDate, endDate, trendPeriod);

  const { isTrendVisitWayLoading, isTrendVisitWayError, TrendVisitWayData, isTrendVisitWaySuccess, TrendVisitWayRefetch, isTrendVisitWayFetching } =
    useTrendVisitWay(prj_cd, qst_cd, startDate, endDate, trendPeriod);

  const [testUsingExpData, setTestUsingExpData] = useState([]);

  useEffect(() => {
    if (deep_ana_cd === "") {
      TrendVisitWayRefetch();
    } else if (deep_ana_cd === "DA01") {
    } else if (deep_ana_cd === "DA03") {
    } else if (deep_ana_cd === "DA04") {
      TrendNpsBasicRefetch();
    } else if (deep_ana_cd === "DA05") {
    } else if (deep_ana_cd === "DA06" && grp_dvs === "SUB") {
      // 만족도
      TrendSatisfactionRefetch();
    } else if (deep_ana_cd === "DA06" && grp_dvs === "SGL") {
      // 진료절차
      TrendMedicalProcRefetch();
    } else if (deep_ana_cd === "DA07") {
    } else if (deep_ana_cd === "DA08") {
    } else if (deep_ana_cd === "DA09") {
    } else if (deep_ana_cd === "DA10") {
      TrendUsingExpRefetch();
    } else if (deep_ana_cd === "DA11") {
    } else if (deep_ana_cd === "DA12") {
    }
  }, [qst_cd, endDate, trendPeriod]); // qst_cd, endDate, trendPeriod

  useEffect(() => {
    const fetchData = async () => {
      if (!deep_ana_cd || menu_cate !== "심화문항") {
        // 내원 경로
        if (TrendVisitWayData !== null && TrendVisitWayData !== undefined) {
          let tempSeries = [];
          let tempSeriesData = [];
          let tempAssignData = [];

          tempAssignData = await exportSelList(TrendVisitWayData.sels);
          tempSeriesData = await GraphDataMatchPeriodVisitWay(TrendVisitWayData.datas, TrendVisitWayData.sels);
          if (tempAssignData.length > 6) setGraphWideFull(true);
          tempSeries.push({
            name: hsp_nm,
            data: tempSeriesData,
          });

          setOptions({
            ...initialOptions,
            chart: {
              type: "column",
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              animation: false,
              height: 400,
              spacing: [10, 10, 10, 10],
            },
            series: tempSeries,
            legend: {
              enabled: false,
              itemStyle: {
                fontSize: "1.4rem",
              },
            },
            xAxis: {
              title: { text: null },
              labels: {
                style: {
                  fontSize: "1.4rem",
                  fontWeight: "bold",
                  fontFamily: "AppleSDGothicNeoRegular",
                },
              },
              tickWidth: 0,
              tickLength: 0,
              categories: tempAssignData,
            },
            plotOptions: {
              series: {
                dataLabels: {
                  style: {
                    fontSize: "1.4rem",
                    textShadow: false,
                    textOutLine: false,
                  },
                  format: "{y}",
                  border: null,
                },
              },
              column: {
                dataLabels: {
                  enabled: true,
                  inside: true,
                  style: {
                    textShadow: 0,
                    textOutline: 0,
                    color: "#000000",
                  },
                },
                stacking: "",
                pointWidth: 600 / (tempAssignData.length * tempSeries.length) > 50 ? 50 : 600 / (tempAssignData.length * tempSeries.length),
              },
            },
          });
        }
      } else if (deep_ana_cd === "DA04") {
        // 일반 NPS
        if (TrendNpsBasicData !== null && TrendNpsBasicData !== undefined) {
          let tempSeries = [];
          let tempSeriesData = [];
          let tempAssignData = [];
          console.log(TrendNpsBasicData);
          tempAssignData = await exportSelList(TrendNpsBasicData.sels); // x축 데이터 값 추출
          tempSeriesData = await exportGraphDataMatchPeriodNPS(TrendNpsBasicData.datas, TrendNpsBasicData.sels); // 그래프 데이터 값 추출
          if (tempAssignData.length > 6) setGraphWideFull(true);

          tempSeries.push({
            name: hsp_nm,
            data: tempSeriesData,
          });

          setOptions({
            ...initialOptions,
            chart: {
              type: "column",
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              animation: false,
              height: 400,
              spacing: [10, 10, 10, 10],
            },
            exporting: {
              scale: 2,
              enabled: false,
              filename: qst + "_" + title,
              chartOptions: {
                chart: {
                  spacing: [30, 15, 30, 15],
                },
                xAxis: {
                  labels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                legend: {
                  itemStyle: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
                plotOptions: {
                  series: {
                    dataLabels: {
                      style: {
                        fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                        fontSize: "12px",
                      },
                    },
                  },
                  column: {
                    dataLabels: {
                      enabled: true,
                      inside: true,
                      style: {
                        textShadow: 0,
                        textOutline: 0,
                        color: "#000000",
                      },
                    },
                    stacking: "",
                    pointWidth: 600 / (tempAssignData.length * tempSeries.length) > 50 ? 50 : 600 / (tempAssignData.length * tempSeries.length),
                  },
                },
              },
            },
            series: tempSeries,
            legend: {
              enabled: false,
              itemStyle: {
                fontSize: "1.4rem",
              },
            },
            xAxis: {
              title: { text: null },
              labels: {
                style: {
                  fontSize: "1.4rem",
                  fontWeight: "bold",
                  fontFamily: "AppleSDGothicNeoRegular",
                },
              },
              tickWidth: 0,
              tickLength: 0,
              categories: tempAssignData,
            },
            plotOptions: {
              series: {
                dataLabels: {
                  style: {
                    fontSize: "1.4rem",
                    textShadow: false,
                    textOutLine: false,
                  },
                  // format: "{y}",
                  border: null,
                },
              },
              column: {
                dataLabels: {
                  enabled: true,
                  inside: true,
                  style: {
                    textShadow: 0,
                    textOutline: 0,
                    color: "#000000",
                  },
                },
                stacking: "",
                pointWidth: 600 / (tempAssignData.length * tempSeries.length) > 50 ? 50 : 600 / (tempAssignData.length * tempSeries.length),
              },
            },
          });
        }
      } else if (deep_ana_cd === "DA06" && grp_dvs === "SUB") {
        // 만족도
        if (TrendSatisfactionData !== null && TrendSatisfactionData !== undefined) {
          const tempAssignData = await exportSelList(TrendSatisfactionData.sels);
          const tempSeries = await GraphDataMatchPeriodUsingExp(TrendSatisfactionData.datas, TrendSatisfactionData.sels);
          if (tempAssignData.length > 6) setGraphWideFull(true);

          setOptions({
            ...initialOptions,
            chart: {
              type: "column",
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              animation: false,
              height: 400,
              spacing: [10, 10, 10, 10],
            },
            series: tempSeries,
            legend: {
              // enabled: false, // false시 그래프 하단에 표시되는 범례를 숨김
              itemStyle: {
                fontSize: "1.4rem",
              },
            },
            xAxis: {
              title: { text: null },
              labels: {
                style: {
                  fontSize: "1.4rem",
                  fontWeight: "bold",
                  fontFamily: "AppleSDGothicNeoRegular",
                },
              },
              tickWidth: 0,
              tickLength: 0,
              categories: tempAssignData,
            },
            plotOptions: {
              series: {
                dataLabels: {
                  style: {
                    fontSize:
                      trendPeriod === "month" ? "1.2rem" : trendPeriod === "year" ? "1.4rem" : trendPeriod === "quarter" ? "1.4rem" : "1.4rem",
                    textShadow: false,
                    textOutLine: false,
                  },
                  format: "{y}",
                  border: null,
                },
              },
              column: {
                dataLabels: {
                  enabled: true,
                  inside: true,
                  style: {
                    textShadow: 0,
                    textOutline: 0,
                    color: "#000000",
                  },
                },
                stacking: "",
                pointWidth: trendPeriod === "month" ? 25 : trendPeriod === "year" ? 29 : trendPeriod === "quarter" ? 29 : 25,
              },
              // bar: {
              //   dataLabels: {
              //     enabled: true,
              //     inside: true,
              //     style: {
              //       textShadow: 0,
              //       textOutline: 0,
              //       color: "#000000",
              //     },
              //   },
              //   stacking: "",
              //   pointWidth: 40,
              // },
            },
          });
        }
      } else if (deep_ana_cd === "DA06" && grp_dvs === "SGL") {
        // 진료 절차
        if (TrendMedicalProcData !== null && TrendMedicalProcData !== undefined) {
          let tempSeries = [];
          let tempSeriesData = [];
          let tempAssignData = [];
          let t = 0;
          tempAssignData = await exportSelList(TrendMedicalProcData.sels); // x축 데이터 값 추출
          tempSeriesData = await exportGraphDataMatchPeriodNPS(TrendMedicalProcData.datas, TrendMedicalProcData.sels); // 그래프 데이터 값 추출
          if (tempAssignData.length > 6) setGraphWideFull(true);

          tempSeries.push({
            name: hsp_nm,
            data: tempSeriesData,
          });

          setOptions({
            ...initialOptions,
            chart: {
              type: "column",
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              animation: false,
              height: 400,
              spacing: [10, 10, 10, 10],
            },
            series: tempSeries,
            legend: {
              enabled: false,
              itemStyle: {
                fontSize: "1.4rem",
              },
            },
            xAxis: {
              title: { text: null },
              labels: {
                style: {
                  fontSize: "1.4rem",
                  fontWeight: "bold",
                  fontFamily: "AppleSDGothicNeoRegular",
                },
              },
              tickWidth: 0,
              tickLength: 0,
              categories: tempAssignData,
            },
            plotOptions: {
              series: {
                dataLabels: {
                  style: {
                    fontSize: "1.4rem",
                    textShadow: false,
                    textOutLine: false,
                  },
                  format: "{y}",
                  border: null,
                },
              },
              column: {
                dataLabels: {
                  enabled: true,
                  inside: true,
                  style: {
                    textShadow: 0,
                    textOutline: 0,
                    color: "#000000",
                  },
                },
                stacking: "",
                pointWidth: 600 / (tempAssignData.length * tempSeries.length) > 50 ? 50 : 600 / (tempAssignData.length * tempSeries.length),
              },
            },
          });
        }
      } else if (deep_ana_cd === "DA10") {
        // 이용 경험
        if (TrendUsingExpData !== null && TrendUsingExpData !== undefined) {
          const tempAssignData = await exportSelList(TrendUsingExpData.sels);
          const tempSeries = await GraphDataMatchPeriodUsingExp(TrendUsingExpData.datas, TrendUsingExpData.sels);
          if (tempAssignData.length > 6) setGraphWideFull(true);

          setOptions({
            ...initialOptions,
            chart: {
              type: "column",
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              animation: false,
              height: 400,
              spacing: [10, 10, 10, 10],
            },

            series: tempSeries,
            legend: {
              enabled: true, // false시 그래프 하단에 표시되는 범례를 숨김
              itemStyle: {
                fontSize: "1.4rem",
              },
            },
            xAxis: {
              title: { text: null },
              labels: {
                style: {
                  fontSize: "1.4rem",
                  fontWeight: "bold",
                  fontFamily: "AppleSDGothicNeoRegular",
                },
              },
              tickWidth: 0,
              tickLength: 0,
              categories: tempAssignData,
            },
            plotOptions: {
              series: {
                dataLabels: {
                  style: {
                    fontSize:
                      trendPeriod === "month" ? "1.2rem" : trendPeriod === "year" ? "1.4rem" : trendPeriod === "quarter" ? "1.4rem" : "1.4rem",
                    textShadow: false,
                    textOutLine: false,
                  },
                  format: "{y}",
                  border: null,
                },
              },
              column: {
                dataLabels: {
                  enabled: true,
                  inside: true,
                  style: {
                    textShadow: 0,
                    textOutline: 0,
                    color: "#000000",
                  },
                },
                stacking: "",
                pointWidth: trendPeriod === "month" ? 25 : trendPeriod === "year" ? 29 : trendPeriod === "quarter" ? 29 : 25,
              },
              // bar: {
              //   dataLabels: {
              //     enabled: true,
              //     inside: true,
              //     style: {
              //       textShadow: 0,
              //       textOutline: 0,
              //       color: "#000000",
              //     },
              //   },
              //   stacking: "normal",
              //   pointWidth: 40,
              // },
            },
          });
        }
      }
    };
    fetchData();
  }, [TrendNpsBasicData, TrendUsingExpData, TrendVisitWayData, TrendMedicalProcData, TrendSatisfactionData]);

  // useEffect(() => {
  //   if (Object.keys(options).length > 0) {
  //     setOptionCnt(options.xAxis.categories.length);
  //   }
  // }, [options]);

  // if (isTrendNpsBasicLoading || isTrendUsingExpLoading || isTrendSatisfactionLoading || isTrendMedicalProcLoading || isTrendVisitWayLoading)
  //   return <></>;

  return (
    <div className={`float-left ${graphWideFull ? "w-full" : "w-[50%]"} bg-white p-[1.0rem]`}>
      <div className="float-left h-full w-full border-[0.1rem] border-tableBorderColor">
        <div
          className={`float-left h-[3.0rem] w-full border-b-[0.1rem] border-b-tableBorderColor bg-white pl-[1.0rem] pr-[1.0rem] ${
            title !== null && title !== undefined && title !== "" && qst !== null && qst !== undefined && qst !== "" ? "" : "hidden"
          }`}
        >
          <div className="float-left mt-[1.0rem] h-[1.0rem] w-[1.0rem] bg-orangeColor"></div>
          <div className="float-left ml-[1.0rem] h-full w-[calc(100%-17.0rem)] overflow-hidden text-ellipsis break-all text-[1.8rem] font-bold leading-[3.0rem]">
            {title} {trendPeriod === "year" ? "(연도별)" : trendPeriod === "quarter" ? "(분기별)" : ""}
          </div>
          <div className="float-left h-full w-[15.0rem] leading-[6.0rem]">
            <div
              className="float-right ml-[0.5rem] mt-[0.15rem] h-[2.5rem] w-[2.5rem] cursor-pointer bg-download_png bg-[length:100%_100%] bg-center bg-no-repeat"
              onClick={() => {
                downloadChart("png");
              }}
            ></div>
            <div
              className="float-right ml-[0.5rem] mt-[0.15rem] h-[2.5rem] w-[2.5rem] cursor-pointer bg-download_pdf bg-[length:100%_100%] bg-center bg-no-repeat"
              onClick={() => {
                downloadChart("pdf");
              }}
            ></div>
            <div
              className="float-right ml-[0.5rem] mt-[0.15rem] h-[2.5rem] w-[2.5rem] cursor-pointer bg-download_jpeg bg-[length:100%_100%] bg-center bg-no-repeat"
              onClick={() => {
                downloadChart("jpeg");
              }}
            ></div>
            <div
              className="float-right mt-[0.15rem] h-[2.5rem] w-[2.5rem] cursor-pointer bg-download_excel bg-[length:100%_100%] bg-center bg-no-repeat"
              onClick={() => {
                downloadChart("excel");
              }}
            ></div>
          </div>
        </div>
        <div className="float-left h-[8.0rem] w-full border-b-[0.1rem] border-b-tableBorderColor p-[1.0rem] text-[1.6rem] leading-[2.0rem]">
          {qst}
        </div>
        <div className="float-left w-full">
          {Object.keys(options).length > 0 ? (
            <HighchartsReact
              key={`${prj_cd + "_" + svy_ojt + "_" + qst_cd + "_" + sort_type + "_" + data_num + "_" + deep_ana_cd}`}
              id={`${prj_cd + "_" + svy_ojt + "_" + qst_cd + "_" + sort_type + "_" + data_num + "_" + deep_ana_cd}`}
              ref={chart}
              highcharts={Highcharts}
              options={options}
              allowChartUpdate={true}
            />
          ) : (
            <div className="flex h-full w-full flex-col items-center justify-center p-20">
              <ClipLoader color="#1686FF" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrendSummary;
